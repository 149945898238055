body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
header,
main,
footer {
  flex-shrink: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ui.blue.button,
.ui.blue.buttons .button {
  color: #fff;
  text-shadow: none;
  background: #034afd;
  opacity: 0.9;
  border-radius: 10px;
}

.ui.yellow.label,
.ui.yellow.labels .label {
  background-color: #ffe699 !important;
  border-color: #ffe699 !important;
  color: #000 !important;
  margin: 10px 0;
  padding: 10px;
  text-transform: uppercase;
}
@media print {
  .print-section {
    padding: 5px !important;
    display: inline !important;
  }
  .print-card-container {
    max-width: 350px !important;
    margin: 0 auto;
  }
  .print-pharmacy-details {
    background: white;
    box-shadow: 0px 10px 40px rgba(26, 58, 97, 0.1);
    border-radius: 20px;
    font-family: 'Ubuntu';
    padding: 40px !important;
    margin: 10px !important;
  }
  .print-pharmacy-details h3.ui.header {
    color: #1a3a61 !important;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 15px !important;
    line-height: 15px !important;
  }
  .print-pharmacy-details h2.ui.header {
    color: #1a3a61 !important;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    text-align: center;
  }
  .print-pharmacy-details p {
    color: #1a3a61 !important;
    font-family: Ubuntu;
    text-align: center;
  }
}
.share-btn {
  text-align: center;
}
.share-btn .ui.basic.blue.button,
.ui.basic.blue.buttons .button {
  color: #034afd !important;
  outline: auto;
}

.print-rodal .rodal-dialog {
  overflow: hidden;
}
.print-preparing {
  position: absolute;
  height: 100%;
  width: 100%;
}

.faq-button {
  background-color: #ffe69a !important;
  margin-bottom: 20px !important;
}
